.main_header{
    width: 100%;
    height: 75px;
    padding: 20px 1% 20px 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}
.search{
    width: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search_input{
    height: 70%;
    margin-right: 10px;
    background-color:var(--input-color);
    color: var(--primary-color);
    border:solid 1px var(--primary-color);
    border-radius: 4px;
}
.search_input::placeholder  {
    color: var(--primary-color);
    text-align: center;
}
.search_logo{
    color: var(--primary-color);
}
.search_logo:hover{
    transform: scale(1.2);
}
.avatar{
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.avatar:hover{
    transform: scale(1.2);
}
.logo >span{
  color: var(--primary-color);
}

.upload{
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
}

@media only screen and (max-width: 600px) {
    .main_header{
        width: 100%;
        height: 50px;
        padding: 20px 1% 20px 1%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
    }

    .search_input{
        margin-top: 60px;
        height: 40px;
        width: 50% ;
    }
    .search_logo{
        margin-top: 60px;
    }
}
  