.control_song{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    color: var(--primary-color);

}

.TbPlayerSkipBack:hover{
transform: scale(1.1);
}

.TbPlayerPlay:hover{
transform: scale(1.1);
}

.TbPlayerPause:hover{
transform: scale(1.1);
}

.TbPlayerSkipForward:hover{
transform: scale(1.1);
}