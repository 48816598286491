.formLoginContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formLogin{
    height: 45%;
    width: 20%;
    padding:3% 2% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var( --primary-color);
    box-shadow: 2px 5px 8px 10px rgba(0, 0, 0, 0.484);
    border-radius: 10%;
}
.header{
    margin-bottom: 5%;
}
.inputLogin{
    width: 70%;
    height: 30px;
    background-color: var(--input-color);
    color: var(--text-primary-color);
    margin: 3.5%;
}
.login_buttons{
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.login_button{
    width: 50px;
    height: 50px;
    color: var(--text-primary-color);
    background-color: var( --primary-color);
    font-size: var(--font-size-label);
    border: none;
}
.login_button:hover{
    transform: scale(1.3);
}

@media only screen and (max-width: 600px) {
    .formLogin{
        height: 40%;
        width: 50%;
        padding:3% 2% 3% 2%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var( --primary-color);
        box-shadow: 2px 5px 8px 10px rgba(0, 0, 0, 0.484);
        border-radius: 10%;
    }
       
}