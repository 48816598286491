
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" );

*{
    direction: ltr;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-size: var(--font-size-text);
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    background: var(--bg-color);
}

:root {
    --bg-color: #1E1E1E;
    --bg-secondary-color: #2C2C2C;
    --text-color: #fff;
    --text-secondary-color: #B6B6B6;
    --primary-color: #56C493;
    --input-color: #383838;
    --font-size-label: 17px;
    --font-size-text: 25px;
    --border-radius: 4px;
}