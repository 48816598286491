.nav_bar{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid var(--primary-color);
}

.btn_nav_bar{
    color: var(--text-secondary-color);
    background-color: var(--bg-secondary-color);
    border:none;
    padding: 1%;
    margin-top: 3%;
    font-size: var(--font-size-text);
}
.btn_nav_bar:active{
    font-size: larger;
}

.btn_nav_bar:hover{
    font-weight: bold;
}


@media only screen and (max-width: 600px){
    .nav_bar{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: normal;
        border-top: none;
    }   
}