.playlistContainer {
  margin: 1% 0 1% 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  align-content: start;
  flex-wrap: wrap;
  
}

.playlist_title {
  text-align: center;
}

.playlist_header{
  color: var(--primary-color);
}

.playlist_input {
  height: 30px;
  background-color: var(--input-color);
  color: var(--primary-color);
  border: solid 1px var(--primary-color);
  border-radius: 4px;
}
.playlist_input::placeholder {
  color: var(--primary-color);
  text-align: center;
}

.playlist_card {
  margin: 1%;
  padding: 1%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 23%;
  height: 50px;
  border-radius: 5%;
  font-size: 16px;
  background-color: var(--bg-secondary-color);
}
.playlist_card:hover {
  box-shadow: 2px 5px 8px 10px rgba(0, 0, 0, 0.27);
}

.playlist_icons{
  font-size: 25px;
  margin-left: 1%;
  color: var(--primary-color);
}

.plus_icon{
  font-size: 23.5px;
}

.playlist_icons:hover{
  transform: scale(1.3);
}

.control_button{
  width: 100%;
  display: flex;
  flex-direction: row;
}