.layout{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.header{
    width: 100%;
    height: 10%;
    background-color: var(--bg-secondary-color);
}

.main{
    width: 100%;
    height: 90%;
}