.popupsong{
    position: fixed;
    bottom: 0;
}


.song_button{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    width: 45%;
    height: 50px;
    margin: 5% 0 5% 0;
    box-shadow: 2px 5px 8px 10px rgba(0,0,0,0.27);
    background-color:var( --bg-color);
}

.video{
    visibility: hidden;
    width: 100%;
    height: 0;
}

.showVideo{
    visibility: visible;
}

.video_button{
    width: 30%;
    height: 70%;
    border: none;
    background-color:var( --bg-color);
    color: var(--primary-color);
    font-size: var(--font-size-label);
}
.video_button:hover{
   transform: scale(1.2);
}